<template>
  <button class="close-button" @click="$emit('click')"></button>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.close-button {
  background: transparent;
  position: absolute;
  top: 10px;
  right: 12px;
  width: 18px;
  height: 18px;
  padding: 0;
  border: none;

  &:before,
  &:after {
    background: $brand-color-2;
    position: absolute;
    top: 0;
    left: 9px;
    content: " ";
    height: 18px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:focus {
    outline: none;
  }
}
</style>

<script>
export default {
  name: "CloseButton",
};
</script>