import Vue from "vue";
// import * as GmapVue from "gmap-vue";
import VueGtag from "vue-gtag";
import VueResize from "vue-resize";
import vSelect from "vue-select";
import { isIOS, isMacOs, isMobileOnly } from "mobile-device-detect";

import { store } from "./store";
import { router } from "./router";
import { api } from "./services/api.service";
import Alert from "./components/Alert";
import BackNav from "./components/BackNav";
import CloseButton from "./components/CloseButton";
import Preloader from "./components/Preloader";
import SmoothReflow from "./components/SmoothReflow";
import Spinner from "./components/Spinner";

import "bootstrap/dist/css/bootstrap.css";
import "leaflet/dist/leaflet.css";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import "vue2-timepicker/dist/VueTimepicker.css";
import "vue-select/dist/vue-select.css";
import "vue-resize/dist/vue-resize.css";

import App from "./App";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.prototype.$http = api;

Vue.component("v-select", vSelect);
Vue.component("Alert", Alert);
Vue.component("BackNav", BackNav);
Vue.component("CloseButton", CloseButton);
Vue.component("Preloader", Preloader);
Vue.component("SmoothReflow", SmoothReflow);
Vue.component("Spinner", Spinner);

Vue.use(VueResize);

// Vue.use(GmapVue, {
//   load: {
//     key: process.env.VUE_APP_GOOGLE_API_KEY,
//     // libraries: "drawing,geometry",
//   },
//   installComponents: true,
// });

Vue.use(
  VueGtag,
  {
    bootstrap: false,
  },
  router
);

Vue.mixin({
  computed: {
    $isIOS() {
      return isIOS;
    },
    $isMacOS() {
      return isMacOs;
    },
    $isMobile() {
      return isMobileOnly;
    },
    $isDevEnv() {
      return process.env.NODE_ENV === "development";
    },
    $disableMap() {
      return this.$isDevEnv && process.env.VUE_APP_DISABLE_MAP === "true";
    },
  },
});

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
