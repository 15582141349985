<template>
  <div class="province-city-selector-component">
    <template v-if="favourites.length">
      <div class="title">Favourites</div>
      <div class="grid-container">
        <div v-for="item in favourites" :key="'favourite-' + slugify(item.name)" class="province-city-option" :class="{ selected: favourites.includes(item) }">
          <div class="form-check">
            <input
              :id="'favourite-' + slugify(item.name)"
              :checked="true"
              class="form-check-input rounded-circle"
              type="checkbox"
              @change="favouriteCheck(item)"
            />
            <label class="form-check-label" :for="'favourite-' + slugify(item.name)">{{ item.name }}</label>
          </div>
          <div class="bar-graph">
            <div class="bar" :style="{ width: (item.value / highestValueForProvince) * 100 + '%' }"></div>
            <div class="value">{{ item.value.toLocaleString("en-ZA") }}</div>
          </div>
        </div>
      </div>
      <div class="separator"></div>
    </template>
    <div class="title">Provinces</div>
    <div class="grid-container">
      <div
        v-for="item in selectedProvinces"
        :key="'province-' + slugify(item.name)"
        class="province-city-option"
        :class="{ selected: selectedProvinces.includes(item) }"
      >
        <div class="form-check">
          <input
            :id="'province-' + slugify(item.name)"
            :checked="selectedProvinces.includes(item)"
            class="form-check-input rounded-circle"
            type="checkbox"
            @change="provinceCheck($event, item)"
          />
          <label class="form-check-label" :for="'province-' + slugify(item.name)">{{ item.name }}</label>
        </div>
        <div class="bar-graph">
          <div class="bar" :style="{ width: (item.value / highestValueForProvince) * 100 + '%' }"></div>
          <div class="value">{{ item.value.toLocaleString("en-ZA") }}</div>
        </div>
      </div>
      <div v-for="item in availableProvinces" :key="'province-' + slugify(item.name)" class="province-city-option">
        <div class="form-check">
          <input :id="'province-' + slugify(item.name)" class="form-check-input rounded-circle" type="checkbox" @change="provinceCheck($event, item)" />
          <label class="form-check-label" :for="'province-' + slugify(item.name)">{{ item.name }}</label>
        </div>
        <div class="bar-graph">
          <div class="bar" :style="{ width: (item.value / highestValueForProvince) * 100 + '%' }"></div>
          <div class="value">{{ item.value.toLocaleString("en-ZA") }}</div>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="title">Cities</div>
    <div class="grid-container">
      <div v-for="item in selectedCities" :key="'city-' + slugify(item.name)" class="province-city-option" :class="{ selected: selectedCities.includes(item) }">
        <div class="form-check">
          <input
            :id="'city-' + slugify(item.name)"
            :checked="selectedCities.includes(item)"
            class="form-check-input rounded-circle"
            type="checkbox"
            @change="cityCheck($event, item)"
          />
          <label class="form-check-label" :for="'city-' + slugify(item.name)">{{ item.name }}</label>
        </div>
        <div class="bar-graph">
          <div class="bar" :style="{ width: (item.value / highestValueForCity) * 100 + '%' }"></div>
          <div class="value">{{ item.value.toLocaleString("en-ZA") }}</div>
        </div>
      </div>
      <div v-for="item in availableCities" :key="'city-' + slugify(item.name)" class="province-city-option">
        <div class="form-check">
          <input :id="'city-' + slugify(item.name)" class="form-check-input rounded-circle" type="checkbox" @change="cityCheck($event, item)" />
          <label class="form-check-label" :for="'city-' + slugify(item.name)">{{ item.name }}</label>
        </div>
        <div class="bar-graph">
          <div class="bar" :style="{ width: (item.value / highestValueForCity) * 100 + '%' }"></div>
          <div class="value">{{ item.value.toLocaleString("en-ZA") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.province-city-selector-component {
  .title {
    font-size: 20px;
    font-weight: bold;
    color: $brand-color-2;
    margin-bottom: 15px;
  }
  .separator {
    margin: 10px 0 20px 0;
    border-bottom: 1px solid $border-grey;
  }

  .grid-container {
    display: grid;
    grid-auto-rows: 1fr;
  }

  .province-city-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 28px;
    margin-bottom: 5px;

    .form-check {
      flex: 1;
      margin-bottom: 3px;
      .form-check-input {
        border-color: $brand-color-1;
        box-shadow: none;

        &:checked {
          background-color: $brand-color-1;

          &[type="checkbox"] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
          }
        }

        & + label {
          // line-height: 1;
          vertical-align: middle;
        }
      }
    }

    .bar-graph {
      flex: 0 0 120px;
      position: relative;
      height: 100%;
      max-height: 28px;

      .bar {
        background: $graph-grey;
        float: right;
        height: 100%;
        padding: 3px 15px;
        // border-right: 2px solid $graph-grey;
      }

      .value {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        z-index: 1;
        line-height: 1;
      }
    }

    &.selected {
      .bar-graph {
        .bar {
          // background: $brand-color-2;
          // border-right-color: $brand-color-1;
        }

        .value {
          font-weight: bold;
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import { utilService } from "@/services";

export default {
  name: "ProvinceCitySelector",
  props: {
    provinces: {
      type: Array,
      required: true,
    },
    cities: {
      type: Array,
      required: true,
    },
    selectProvince: {
      type: Object,
    },
    selectCity: {
      type: Object,
    },
  },
  data() {
    return {
      selectedProvinces: [],
      selectedCities: [],
    };
  },
  computed: {
    ...mapGetters("provinceCitySelector", {
      selectedProvinceNames: "getSelectedProvinceNames",
      selectedCityNames: "getSelectedCityNames",
    }),
    favourites() {
      let favourites = this.selectedProvinces.concat(this.selectedCities);
      favourites.sort((a, b) => b.value - a.value);

      return favourites;
    },
    availableProvinces() {
      return this.provinces.filter((item) => !this.selectedProvinces.includes(item));
    },
    availableCities() {
      return this.cities.filter((item) => !this.selectedCities.includes(item));
    },
    highestValueForProvince() {
      return this.provinces[0].value;
    },
    highestValueForCity() {
      return this.cities[0].value;
    },
  },
  mounted() {
    this.initSelectedProvinces(this.provinces);
    this.initSelectedCities(this.cities);
  },
  methods: {
    ...mapMutations("provinceCitySelector", {
      selectProvinceInStore: "selectProvince",
      deselectProvinceInStore: "deselectProvince",
      selectCityInStore: "selectCity",
      deselectCityInStore: "deselectCity",
    }),
    slugify(str) {
      return utilService.slugify(str);
    },
    initSelectedProvinces(provinces) {
      this.selectedProvinces = [];
      provinces.forEach((province) => {
        if (this.selectedProvinceNames.includes(province.name)) {
          this.selectedProvinces.push(province);
        }
      });
    },
    initSelectedCities(cities) {
      this.selectedCities = [];
      cities.forEach((city) => {
        if (this.selectedCityNames.includes(city.name)) {
          this.selectedCities.push(city);
        }
      });
    },
    favouriteCheck(value) {
      if (this.selectedProvinces.includes(value)) {
        this.selectedProvinces = this.selectedProvinces.filter((item) => item.name !== value.name);
        this.selectedProvinces.sort((a, b) => b.value - a.value);
        this.deselectProvinceInStore(value.name);
      } else {
        this.selectedCities = this.selectedCities.filter((item) => item.name !== value.name);
        this.selectedCities.sort((a, b) => b.value - a.value);
        this.deselectCityInStore(value.name);
      }
    },
    provinceCheck(event, province) {
      if (event.target.checked) {
        this.selectedProvinces.push(province);
        this.selectProvinceInStore(province.name);
      } else {
        this.selectedProvinces = this.selectedProvinces.filter((item) => item.name !== province.name);
        this.deselectProvinceInStore(province.name);
      }

      this.selectedProvinces.sort((a, b) => b.value - a.value);
    },
    cityCheck(event, city) {
      if (event.target.checked) {
        this.selectedCities.push(city);
        this.selectCityInStore(city.name);
      } else {
        this.selectedCities = this.selectedCities.filter((item) => item.name !== city.name);
        this.deselectCityInStore(city.name);
      }

      this.selectedCities.sort((a, b) => b.value - a.value);
    },
  },
  watch: {
    provinces(value) {
      this.initSelectedProvinces(value);
    },
    cities(value) {
      this.initSelectedCities(value);
    },
    selectProvince(value) {
      this.selectedProvinces.push(value);
    },
    selectedProvinces(value) {
      this.$emit("selectedProvinces", value);
      this.$emit("favourites", this.favourites);
    },
    selectCity(value) {
      this.selectedCities.push(value);
    },
    selectedCities(value) {
      this.$emit("selectedCities", value);
      this.$emit("favourites", this.favourites);
    },
  },
};
</script>