import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export default class AuthService {
  constructor(clientId, authority, knownAuthority, scopes, redirectUri) {
    this.app = new PublicClientApplication({
      auth: {
        clientId: clientId,
        authority: authority,
        knownAuthorities: [knownAuthority],
        redirectUri: redirectUri,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
      // system: {
      //   loggerOptions: {
      //     loggerCallback: (level, message, containsPii) => {
      //       if (containsPii) {
      //         return;
      //       }
      //       switch (level) {
      //         case LogLevel.Error:
      //           console.error(message);
      //           return;
      //         case LogLevel.Info:
      //           console.info(message);
      //           return;
      //         case LogLevel.Verbose:
      //           console.debug(message);
      //           return;
      //         case LogLevel.Warning:
      //           console.warn(message);
      //           return;
      //       }
      //     },
      //     piiLoggingEnabled: false,
      //   },
      // },
    });

    this.scopes = scopes;
    // console.log(this.scopes);
    // this.scopes = ["openid"];
  }

  async login() {
    this.app.handleRedirectPromise().then((response) => {
      // console.log(response);
      this.acquireToken().then((accessToken) => {
        // console.log("Access Token: " + accessToken);
      });
    });
  }

  async acquireToken() {
    // Set scopes for token request
    const accessTokenRequest = {
      scopes: this.scopes,
      account: this.getUser(),
    };

    let tokenResp;

    try {
      // 1. Try to acquire token silently
      tokenResp = await this.app.acquireTokenSilent(accessTokenRequest);
      // console.log("### MSAL acquireTokenSilent was successful");
    } catch (err) {
      // 2. Silent process might have failed so try via redirect
      tokenResp = await this.app.acquireTokenRedirect(accessTokenRequest);
      // console.log("### MSAL acquireTokenPopup was successful");
    }

    return tokenResp.accessToken ? tokenResp.accessToken : null;
  }

  logout() {
    this.app.logoutRedirect();
  }

  getUser() {
    const currentAccounts = this.app.getAllAccounts();

    if (currentAccounts && currentAccounts.length > 0) {
      return currentAccounts[0];
    }

    return null;
  }
}
