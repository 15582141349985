<template>
  <div>
    <div class="donut-chart-container">
      <div class="chart">
        <apexchart type="donut" :options="options" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.donut-chart-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .chart {
    width: 100%;
    max-width: 500px;
  }
}

.attribution {
  font-size: 18px;
  margin-top: 30px;
}
</style>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "DonutChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    colors: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        chart: {
          fontFamily: "Mont, Arial, sans-serif",
          foreColor: "#536e89",
          toolbar: {
            show: false,
          },
        },
        colors: this.colors.length === this.datasets.length ? this.colors : [],
        labels: this.datasets.map((item) => item.name),
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "55%",
              labels: {
                show: true,
                // name: {
                //   show: true,
                // },
                // value: {
                //   show: true,
                // },
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: "18px",
                  fontWeight: "bold",
                },
              },
            },
          },
        },
      },
      series: this.datasets.map((item) => item.value),
    };
  },
};
</script>