<template>
  <button class="back-nav-component btn-wrapper" @click="$emit('click')">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
    </svg>
    <div class="label">{{ this.label }}</div>
  </button>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.back-nav-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $brand-color-1;

  .label {
    font-weight: bold;
    line-height: 24px;
    margin-left: 5px;
  }
}
</style>

<script>
export default {
  name: "BackNav",
  props: {
    label: {
      type: String,
    },
  },
};
</script>