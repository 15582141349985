import Vue from "vue";
import Router from "vue-router";
import HomePage from "@/pages/HomePage";
import LoginPage from "@/pages/LoginPage";
import TrafficVolumesPage from "@/pages/TrafficVolumesPage";
import RemoteJammingAlertsPage from "@/pages/RemoteJammingAlertsPage";
import DrivingBehaviourPage from "@/pages/DrivingBehaviourPage";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    { name: "Home", path: "/", component: HomePage },
    { name: "Login", path: "/login", component: LoginPage },
    {
      name: "TrafficVolumes",
      path: "/traffic-volumes",
      component: TrafficVolumesPage,
      props: true,
    },
    {
      name: "RemoteJammingAlerts",
      path: "/remote-jamming-alerts",
      component: RemoteJammingAlertsPage,
    },
    {
      name: "DrivingBehaviour",
      path: "/driving-behaviour",
      component: DrivingBehaviourPage,
      props: true,
    },

    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
});

/*router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // if (authRequired && !loggedIn) {
  //   return next("/login");
  // }

  next();

  window.scrollTo(0, 0);
});*/
