<template>
  <div class="date-picker-component">
    <template v-if="label">
      <div class="label" :style="{ width: labelWidth }">{{ labelValue }}</div>
      <div class="separator" />
    </template>
    <div class="date-icon">
      <img src="~@/assets/images/calendar.svg" width="16" height="16" alt />
    </div>
    <div class="flex-grow-1">
      <VueDatePicker format="YYYY-MM-DD" color="#4CC3E1" noHeader fullscreenMobile :minDate="minDate" :maxDate="maxDate" v-model="dateValue" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.date-picker-component {
  display: flex;
  align-items: center;
  min-width: 160px;
  padding: 0 10px;
  border: 1px solid $brand-color-1;

  background: url('data:image/svg+xml,%3Csvg viewBox="0 0 16 16" fill="%23536e89" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3E%3C/svg%3E')
    no-repeat right 0.5rem center;
  background-size: 18px 18px;

  > div {
    &.label {
      font-style: italic;
      color: $brand-color-2;
      text-align: right;
    }

    &.separator {
      position: relative;
      margin: 0 10px;

      &::before {
        content: " ";
        position: absolute;
        top: -10px;
        left: 0;
        width: 1px;
        height: 20px;
        border-left: 1px solid $brand-color-2;
      }
    }

    &.date-icon {
      img {
        vertical-align: top;
      }
    }
  }
}
</style>

<script>
import { VueDatePicker } from "@mathieustan/vue-datepicker";

export default {
  name: "DatePicker",
  components: {
    VueDatePicker,
  },
  props: {
    label: {
      type: String,
    },
    labelWidth: {
      type: String,
      default: "auto",
    },
    minDate: {
      type: String,
    },
    maxDate: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      dateValue: this.value,
    };
  },
  computed: {
    labelValue() {
      return this.label ? this.label : "DATE";
    },
  },
  watch: {
    value() {
      this.dateValue = this.value;
    },
    dateValue() {
      this.$emit("input", this.dateValue);
    },
  },
};
</script>