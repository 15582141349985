<template>
  <transition name="fade">
    <div id="dialog-container" v-show="showDialogs">
      <transition-group name="scale" tag="div">
        <Dialog v-for="dialog in dialogs" :key="dialog.id" v-bind="dialog" :active="showDialogs" @close="closeDialog(dialog.id)" />
      </transition-group>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

#dialog-container {
  background: rgba($brand-color-1, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
}
</style>

<script>
import { mapState } from "vuex";
import { modalService } from "@/services";
import Dialog from "@/components/Dialog";

export default {
  name: "DialogContainer",
  components: {
    Dialog,
  },
  computed: {
    ...mapState("navigation", ["showDialogs", "dialogs"]),
  },
  methods: {
    closeDialog(id) {
      modalService.closeDialog(id);
    },
  },
};
</script>