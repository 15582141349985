import axios from "axios";
import { router } from "@/router";

export const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": `${process.env.VUE_APP_SUBSCRIPTION_KEY}`,
  },
});

// api.interceptors.request.use(
//   (config) => {
//     const user = JSON.parse(localStorage.getItem("user"));
//     if (user && user.token) {
//       config.headers.common["Authorization"] = user.token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// api.interceptors.response.use(
//   (response) => {
//     if (response.status === 200 || response.status === 201) {
//       return Promise.resolve(response);
//     } else {
//       return Promise.reject(response);
//     }
//   },
//   (error) => {
//     if (error.response && error.response.status) {
//       switch (error.response.status) {
//         case 401:
//           localStorage.removeItem("user");
//           if (router.currentRoute.path !== "/login") {
//             location.reload(true);
//           }
//           break;
//       }

//       const errorMessage = error.response.data && error.response.data.message;

//       return Promise.reject(errorMessage);
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );
