<template>
  <div class="preloader-component">
    <img src="~@/assets/images/netstar-loader.gif" width="100" height="100" alt="Netstar" />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.preloader-component {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
</style>

<script>
export default {
  name: "Preloader",
};
</script>