<template>
  <footer>
    <div class="container">
      <div class="columns">
        <div class="column">
          <img src="~@/assets/images/netstar-logo.svg" width="151" height="54" alt="Netstar" />
          <div class="social-media">
            <a href="https://www.facebook.com/NetstarSA/" target="_blank">
              <img src="~@/assets/images/facebook.svg" width="28" height="28" alt="Facebook" />
            </a>
            <a href="https://twitter.com/NetstarSA" target="_blank">
              <img src="~@/assets/images/twitter.svg" width="28" height="28" alt="Twitter" />
            </a>
            <a href="https://www.youtube.com/channel/UC-sTZQINhaWtrpNgP3EYefw" target="_blank">
              <img src="~@/assets/images/youtube.svg" width="28" height="28" alt="YouTube" />
            </a>
            <a href="https://www.linkedin.com/company/netstar-sa" target="_blank">
              <img src="~@/assets/images/linkedin.svg" width="28" height="28" alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div class="column">
          <div class="title">About Netstar</div>
          <div>
            Over 12 000 Netstar tracking devices are installed per month, through a network of over 200 experienced mobile technicians conveniently installing
            your tracking device at a location of your choice. Netstar provides a wide range of fleet management solutions and vehicle tracking devices and our
            locally manufactured products have stood the test of time.
          </div>
        </div>
        <div class="column">
          <div class="title">Contact Us</div>
          <ul class="list-unstyled">
            <li>
              <div>Netstar Head Office<br />Central Park Offices<br />16th Road, Midrand<br />1685</div>
            </li>
            <li>
              <div><a href="tel:+27112075500">+27 11 207 5500</a></div>
            </li>
            <li>
              <div><a href="mailto:cs@netstar.co.za">cs@netstar.co.za</a></div>
            </li>
          </ul>
        </div>
        <div class="column">
          <div class="title">Netstar Gets It Right</div>
          <ul class="list-unstyled">
            <li>
              <div>21 Years' experience</div>
            </li>
            <li>
              <div>Air and ground recovery</div>
            </li>
            <li>
              <div>Beyond the borders</div>
            </li>
            <li>
              <div>Affordable Prices</div>
            </li>
            <li>
              <div>Award-winning technology</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="links">
        <span>Copyright Netstar 2022</span>
        <span class="separator">|</span>
        <span>Developed by Fishgate</span>
        <span class="separator">|</span>
        <a href="#" target="_blank">Access to Information</a>
        <span class="separator">|</span>
        <a href="#" target="_blank">Disclaimer</a>
        <span class="separator">|</span>
        <a href="#" target="_blank">Sitemap</a>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

footer {
  background: $brand-color-4;
  font-size: 12px;
  padding: 50px 50px 40px 50px;

  .container {
    max-width: 1080px;

    .columns {
      display: flex;
      justify-content: space-evenly;

      .column {
        max-width: 300px;
      }
    }

    .links {
      text-align: center;
      padding-top: 25px;

      .separator {
        display: inline-block;
        color: $brand-color-2;
        margin: 0 10px;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    color: $brand-color-2;
    margin-bottom: 15px;
  }

  .social-media {
    display: flex;
    justify-content: space-between;
    width: 150px;
    margin-top: 30px;
  }

  ul {
    li {
      position: relative;
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        position: absolute;
        height: 15px;
        border-left: 2px solid $brand-color-2;
      }

      > div {
        padding-left: 10px;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Footer",
};
</script>
