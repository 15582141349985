import { api } from "@/services/api.service";

export const userService = {
  login,
  logout,
};

function login(username, password) {
  return new Promise((resolve, reject) => {
    const user = {
      firstName: "Joe",
      surname: "Soap",
      organisation: "SABC",
      token: "12345",
    };
    localStorage.setItem("user", JSON.stringify(user));
    resolve(user);
  });
  // return api
  //   .post("Authorisation/login", { username: username, password: password })
  //   .then((response) => {
  //     const result = response.data;
  //     let user = result.data.authenticationUser;
  //     user.token = result.data.token;
  //     localStorage.setItem("user", JSON.stringify(user));

  //     return user;
  //   });
}

function logout() {
  localStorage.removeItem("user");
}
