function initialState() {
  return {
    parentScreenKey: 0,
    show: false,
    showDialogs: false,
    dialogs: [],
    showModal: false,
    modalWidth: null,
    modalComponent: null,
    modalComponentProps: null,
    modalCount: 0,
  };
}

const state = initialState();

const actions = {
  triggerParentScreenRefresh({ commit }) {
    commit("updateParentScreenKey");
  },
};

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  updateParentScreenKey(state) {
    state.parentScreenKey += 1;
  },
  updateShow(state, value) {
    state.show = value;
  },
  addDialog(state, value) {
    state.dialogs.push(value);
    state.showDialogs = true;
  },
  removeDialog(state, value) {
    state.dialogs = state.dialogs.filter((obj) => obj.id !== value);
    state.showDialogs = state.dialogs.length !== 0;
  },
  updateShowModal(state, value) {
    if (value) {
      state.modalCount++;
    } else {
      state.modalCount--;
    }
    state.showModal = value;
  },
  updateModalWidth(state, value) {
    state.modalWidth = value;
  },
  updateModalComponent(state, value) {
    state.modalComponent = value;
  },
  updateModalComponentProps(state, value) {
    state.modalComponentProps = value;
  },
  updateModalCount(state, value) {
    state.modalCount = value;
  },
};

export const navigation = {
  namespaced: true,
  state,
  actions,
  mutations,
};
