import { store } from "@/store";
import { api } from "@/services/api.service";

export const statsService = {
  getTrafficVolumes,
  getTrafficVolumesForDate,
  getTrafficVolumesForProvince,
  getTrafficVolumesForProvinceAndDate,
  getRemoteJammingAlerts,
  getRemoteJammingAlertsForDate,
  getRemoteJammingAlertsForProvince,
  getRemoteJammingAlertsForProvinceAndDate,
};

function getTrafficVolumes() {
  return api.get("getTrafficVolumes").then((request) => {
    return request.data;
  });
}

function getTrafficVolumesForDate(date) {
  return api.get("getTrafficVolumes?date=" + date).then((request) => {
    return request.data;
  });
}

function getTrafficVolumesForProvince(province) {
  return api.get("getTrafficVolumes?province=" + province).then((request) => {
    return request.data;
  });
}

function getTrafficVolumesForProvinceAndDate(province, date) {
  return api
    .get("getTrafficVolumes?province=" + province + "&date=" + date)
    .then((request) => {
      return request.data;
    });
}

function getRemoteJammingAlerts() {
  return api.get("getRemoteJammingAlerts").then((request) => {
    return request.data;
  });
}

function getRemoteJammingAlertsForDate(date) {
  return api.get("getRemoteJammingAlerts?date=" + date).then((request) => {
    return request.data;
  });
}

function getRemoteJammingAlertsForProvince(province) {
  return api
    .get("getRemoteJammingAlerts?province=" + province)
    .then((request) => {
      return request.data;
    });
}

function getRemoteJammingAlertsForProvinceAndDate(province, date) {
  return api
    .get("getRemoteJammingAlerts?province=" + province + "&date=" + date)
    .then((request) => {
      return request.data;
    });
}
