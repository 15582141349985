<template>
  <div>
    <div class="line-chart-container">
      <div class="chart">
        <apexchart width="100%" type="line" :options="options" :series="series"></apexchart>
      </div>
    </div>

    <div class="attribution" v-if="printscreen">Supplied by Netstar</div>
  </div>
</template>

<style lang="scss" scoped>
.line-chart-container {
  display: flex;
  align-items: center;

  .chart {
    flex: 1;
  }

  .legend {
    width: 150px;

    .item {
      font-weight: bold;
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.attribution {
  font-size: 18px;
  margin-top: 30px;
}
</style>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  name: "LineChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    printscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        chart: {
          fontFamily: "Mont, Arial, sans-serif",
          foreColor: "#536e89",
          toolbar: {
            show: !this.printscreen,
          },
        },
        // title: {
        //   text: "Traffic Volume Insights",
        //   align: "left",
        //   margin: 10,
        //   offsetX: 0,
        //   offsetY: 0,
        //   floating: false,
        //   style: {
        //     fontSize: "40px",
        //     fontWeight: "bold",
        //     fontFamily: "Mont",
        //     color: "#536e89",
        //   },
        // },
        // subtitle: {
        //   text: "In South Africa, on 26 August 2022",
        //   align: "left",
        //   margin: 10,
        //   offsetX: 0,
        //   offsetY: 55,
        //   floating: false,
        //   style: {
        //     fontSize: "20px",
        //     fontWeight: "400",
        //     fontFamily: "Mont",
        //     color: "#536e89",
        //   },
        // },
        colors: this.datasets.map((item) => item.color),
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories:
            this.datasets[0] && this.datasets[0].timeSeries ? this.datasets[0].timeSeries.map((dataPoint) => moment(dataPoint.dateTime).format("HH:mm")) : [],
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },

        grid: {
          show: true,
          borderColor: "#00000029",
          strokeDashArray: 2,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          position: "right",
          fontWeight: "bold",
        },
      },
      series: [],
    };
  },
  mounted() {
    this.datasets.forEach((item) => {
      if (item.timeSeries) {
        this.series.push({
          name: item.name,
          data: item.timeSeries.map((dataPoint) => dataPoint.value),
        });
      }
    });
  },
};
</script>