import AuthService from "@/services/auth.service";

const clientid = process.env.VUE_APP_CLIENT_ID;
const authority = process.env.VUE_APP_AUTHORITY;
const knownAuthority = process.env.VUE_APP_KNOWN_AUTHORITY;
const redirectUri = process.env.VUE_APP_REDIRECT_URI;
const readScope = process.env.VUE_APP_SCOPE_READ;
const writeScope = process.env.VUE_APP_SCOPE_WRITE;
// const scopes = ["openid"];
const scopes = [readScope, writeScope];

// console.log(`clientid = ${clientid}`);
// console.log(`authority = ${authority}`);
// console.log(`knownAuthority = ${knownAuthority}`);
// console.log(`redirectUri = ${redirectUri}`);
// console.log(`scopes = ${scopes}`);

const authservice = new AuthService(
  clientid,
  authority,
  knownAuthority,
  scopes,
  redirectUri
);

const state = {
  isAuthenticated: false,
  user: {},
};

const mutations = {
  updateIsAuthenticated(state, value) {
    state.isAuthenticated = value;
  },
  updateUser(state, value) {
    state.user = value;
  },
};

const actions = {
  login({ commit }) {
    return new Promise((resolve, reject) => {
      authservice.login().then(() => {
        const userCheck = setInterval(() => {
          const user = authservice.getUser();
          if (user != null) {
            commit("updateUser", user);
            commit("updateIsAuthenticated", true);
            clearInterval(userCheck);
            resolve();
          }
        }, 1000);
      });
    });
  },
  logout({ commit }) {
    commit("updateUser", {});
    commit("updateIsAuthenticated", false);
    authservice.logout();
  },
};

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
};
