<template>
  <div class="menu">
    <ul class="menu__navbar">
      <li class="menu__item">
        <transition name="dropdown">
          <div class="dropdown__menu" :class="{ active: show }" v-if="show">
            <ul class="dropdown__menu-nav">
              <hr />
              <li class="dropdown__menu-item">
                <div class="dropdown__menu-link" @click="handleLogout">
                  <div class="dropdown__menu-text">Logout</div>
                </div>
              </li>
            </ul>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.menu {
  position: fixed;
  top: 52px;
  right: 0;
  z-index: 2;

  &__navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
  }
}

.dropdown__menu {
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 250px;
  overflow-y: auto;
  padding: 0 15px 15px 15px;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.3);

  &-nav {
    list-style: none;
    padding: 0;
  }

  &-heading {
    font-weight: bold;
    font-size: 0.75rem;
    color: $brand-color-1;
    text-transform: uppercase;
    padding: 6px 10px;
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 0.75rem;
    color: $brand-color-1;
    text-decoration: none;
    text-transform: uppercase;
    padding: 6px 10px;

    &:hover {
      background: $brand-color-3;
      cursor: pointer;
    }

    .secondary & {
      color: $brand-color-2;

      &:hover {
        color: $brand-color-1;
      }
    }
  }

  &-text {
    width: 100%;
    font-weight: bold;

    .secondary & {
      font-weight: 600;
    }
  }

  hr {
    margin: 10px 0;
    border-top: 1px solid $brand-color-2;
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapState("navigation", ["show"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapMutations("navigation", ["updateShow"]),
    handleLogout() {
      this.logout();
    },
  },
};
</script>