import Vue from "vue";
import { store } from "@/store";
import { v4 as uuidv4 } from "uuid";

export const modalService = {
  showSuccessDialog,
  showErrorDialog,
  showSaveDialog,
  showDeleteDialog,
  showRefreshDialog,
  showNotSupportedDialog,
  showSystemNotificationDialog,
  showSuccessToast,
};

function showSuccessDialog(message) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    isSuccess: true,
    title: "SUCCESS",
    message: message,
    buttons: [
      {
        title: "OK",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showErrorDialog(message = "") {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    isError: true,
    title: "ERROR",
    message: message
      ? message
      : "Unable to process request at this time. Please try again.",
    buttons: [
      {
        title: "OK",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showSaveDialog(instance, message, callback) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "SAVE CHANGES",
    message: message,
    buttons: [
      {
        title: "Yes",
        handler: () => {
          const success = callback();
          if (success) {
            instance.$emit("close");
          }
          closeDialog(id);
        },
      },
      {
        title: "No",
        handler: () => {
          instance.$emit("close");
          closeDialog(id);
        },
      },
      {
        title: "Cancel",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showDeleteDialog(message, callback) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "DELETE",
    message: message,
    buttons: [
      {
        title: "Yes",
        handler: () => {
          callback();
          closeDialog(id);
        },
      },
      {
        title: "No",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function showRefreshDialog() {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "UPDATE REQUIRED",
    message: "Updated content is available.",
    buttons: [
      {
        title: "Refresh",
        handler: () => {
          window.location.reload();
        },
      },
    ],
  });
}

function showNotSupportedDialog(instance) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    title: "NOT SUPPORTED",
    message:
      "Unfortunately, this feature is not supported on your current package.",
    buttons: [
      {
        title: "View Upgrade Options",
        handler: () => {
          closeDialog(id);
          showModal(instance, "product-upgrade-options");
        },
      },
      {
        title: "Cancel",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
    width: "450px",
  });
}

function showSystemNotificationDialog(message) {
  const id = uuidv4();
  store.commit("navigation/addDialog", {
    id: id,
    isSystemNotification: true,
    title: "PLEASE NOTE",
    message: message,
    buttons: [
      {
        title: "OK",
        handler: () => {
          closeDialog(id);
        },
      },
    ],
  });
}

function closeDialog(id) {
  store.commit("navigation/removeDialog", id);
}

function showSuccessToast(message) {
  Vue.$toast.open(message);
}
