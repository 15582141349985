<template>
  <div class="toggle-component">
    <img src="~@/assets/images/accordion/chevron-up.svg" width="24" height="24" alt="Collapse" v-if="isActive" />
    <img src="~@/assets/images/accordion/chevron-down.svg" width="24" height="24" alt="Expand" v-else />
  </div>
</template>

<style lang="scss" scoped>
.toggle-component {
}
</style>

<script>
export default {
  name: "Toggle",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  watch: {
    active(value) {
      this.isActive = value;
    },
  },
};
</script>