import { userService } from "@/services";
import { router } from "@/router";

const user = JSON.parse(localStorage.getItem("user"));
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

const actions = {
  login({ dispatch, commit }, { username, password }) {
    commit("loginRequest", { username });

    userService.login(username, password).then(
      (user) => {
        commit("loginSuccess", user);
        router.push("/");
      },
      (error) => {
        commit("loginFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },
  logout({ commit }) {
    userService.logout();
    commit("logout");
    commit("alert/reset", null, { root: true });
    commit("navigation/reset", null, { root: true });
  },
};

const getters = {
  getDisplayName: (state) => {
    if (state.user && state.user.organisation) {
      return state.user.organisation;
    }
    if (state.user && state.user.firstName) {
      return state.user.firstName;
    }
    return "";
  },
  getProfileImageUrl: (state) => {
    if (state.user && state.user.profileImageUrl) {
      return state.user.profileImageUrl;
    }
    return require("@/assets/images/avatar.svg");
  },
  getIsNewUser: (state) => {
    if (state.user) {
      const loginCount = state.user.loginCount;
      return loginCount && loginCount <= 2;
    }
    return false;
  },
  isAuthenticated: (state) => {
    return state.user && state.user.token;
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  updateNickname(state, nickname) {
    state.user.nickname = nickname;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  updateProfileImageUrl(state, profileImageUrl) {
    state.user.profileImageUrl = profileImageUrl;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
