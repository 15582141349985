<template>
  <div class="driving-behaviour-page page">
    <Preloader v-if="!isAuthenticated || loading" />
    <template v-else>
      <Header pageName="DrivingBehaviour" />
      <div class="main">
        <div class="content">
          <div class="content-nav">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              id="sidebar-toggle"
              class="bi bi-list"
              viewBox="0 0 16 16"
              @click="showSidebar = !showSidebar"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
            <div class="column-1">
              <span>Select Provinces and Cities below to build your favourites list.</span>
              <v-select id="search" class="form-control" @input="searchChange" v-model="search" placeholder="SEARCH" label="name" :options="searchOptions">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!search" v-bind="attributes" v-on="events" />
                </template>
              </v-select>
            </div>
            <div class="column-2">
              <DatePicker class="me-3" :maxDate="today" v-model="date" />
              <TimePicker v-model="time" />
            </div>
            <div class="column-3">
              <div class="category-selector">
                <span class="">Sort by</span>
                <button
                  v-for="(item, key) in categories"
                  :key="key"
                  class="btn"
                  :class="{ selected: item.displayName == selectedCategory }"
                  @click="handleCategoryChange(item.displayName)"
                >
                  {{ item.displayName }}
                </button>
              </div>
            </div>
          </div>
          <div class="content-main">
            <div class="sidebar" :class="{ show: showSidebar }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                id="sidebar-close"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
                @click="showSidebar = !showSidebar"
              >
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
                />
              </svg>
              <ProvinceCitySelector
                :provinces="allProvinces"
                :cities="allCities"
                :selectProvince="provinceToSelect"
                :selectCity="cityToSelect"
                @favourites="(a) => (favourites = a)"
                @selectedProvinces="(a) => (selectedProvinces = a)"
                @selectedCities="(a) => (selectedCities = a)"
              />
            </div>
            <div class="panel" :class="{ fullscreen: isFullscreen, printscreen: isPrintscreen }" @mousemove="handleMouseMove">
              <div class="nav">
                <div>
                  <button class="btn-rounded nav-outline me-3" :class="{ selected: isCategoryOverview }" @click="handleCategoryViewTypeChange('overview')">
                    OVERVIEW
                  </button>
                  <button class="btn-rounded nav-outline me-3" :class="{ selected: isCategoryMap }" @click="handleCategoryViewTypeChange('map')">MAP</button>
                  <button class="btn-rounded nav-outline" :class="{ selected: isCategoryGraph }" @click="handleCategoryViewTypeChange('graph')">GRAPH</button>
                </div>
                <div class="d-flex align-items-center">
                  <v-select id="viewType" class="form-control" @input="viewTypeChange" v-model="viewType" :options="viewTypes">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!viewType" v-bind="attributes" v-on="events" />
                    </template>
                  </v-select>
                  <button id="printscreen" class="btn" @click="openPrintscreen" v-if="isCategoryMap || isCategoryGraph">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path
                        d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"
                      />
                    </svg>
                  </button>
                  <button id="fullscreen" class="btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      class="bi bi-fullscreen-exit"
                      viewBox="0 0 16 16"
                      @click="closeFullscreen"
                      v-if="isFullscreen"
                    >
                      <path
                        d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      class="bi bi-arrows-fullscreen"
                      viewBox="0 0 16 16"
                      @click="openFullscreen"
                      v-else
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="panel-content">
                <template v-if="favourites.length && isSpeeding">
                  <div class="volumes-overview" v-if="isCategoryOverview">
                    <div v-for="province in selectedProvinces" :key="province.name" class="volumes-overview-item">
                      <div class="volumes-overview-item-header">
                        {{ province.name }}
                      </div>
                      <div class="volumes-overview-item-content" :class="{ up: province.changeDirection == 'up', down: province.changeDirection == 'down' }">
                        <div class="rank">
                          <strong :class="{ 'text-capitalize': isProvinceWithHighestRank(province) }">{{ getProvinceRank(province, "most") }}</strong> speeding
                          incidents of all the provinces.
                        </div>
                        <div class="vehicle-count">
                          <div class="value">
                            {{ province.value.toLocaleString("en-ZA") }}
                          </div>
                          <div>Incidents</div>
                        </div>
                        <div class="vehicle-value-change">
                          <div class="icon">
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="province.changeDirection == 'up'">
                              <path fill="currentColor" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
                            </svg>
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="province.changeDirection == 'down'">
                              <path fill="currentColor" d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" />
                            </svg>
                          </div>
                          <div>
                            <strong>{{ province.changeDirection }} {{ province.percentageChange }}%</strong> compared to yesterday.
                          </div>
                        </div>
                      </div>
                      <div class="volumes-overview-item-footer" v-if="province.cities.length">
                        <div v-for="city in province.cities.slice(0, 3)" :key="city.name" class="city-volume">
                          <div class="name">{{ city.name }}</div>
                          <div class="text-end">
                            <div class="value">{{ city.value.toLocaleString("en-ZA") }}</div>
                            <div>Incidents</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-for="city in selectedCities" :key="city.name" class="volumes-overview-item">
                      <div class="volumes-overview-item-header">
                        {{ city.name }}
                      </div>
                      <div class="volumes-overview-item-content" :class="{ up: city.changeDirection == 'up', down: city.changeDirection == 'down' }">
                        <div class="rank">
                          <strong :class="{ 'text-capitalize': isCityWithHighestRank(city) }">{{ getCityRank(city, "most") }}</strong> speeding incidents of all
                          the cities.
                        </div>
                        <div class="vehicle-count">
                          <div class="value">
                            {{ city.value.toLocaleString("en-ZA") }}
                          </div>
                          <div>Incidents</div>
                        </div>
                        <div class="vehicle-value-change">
                          <div class="icon">
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="city.changeDirection == 'up'">
                              <path fill="currentColor" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
                            </svg>
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="city.changeDirection == 'down'">
                              <path fill="currentColor" d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" />
                            </svg>
                          </div>
                          <div>
                            <strong>{{ city.changeDirection }} {{ city.percentageChange }}%</strong> compared to yesterday.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="volumes-map panel-content-map" v-if="isCategoryMap">
                    <div class="printscreen-header" v-if="isPrintscreen">
                      <button id="close-printscreen" class="btn-rounded nav-outline" :class="{ reveal: revealHiddenElements }" @click="closePrintscreen">
                        CLOSE
                      </button>
                      <div>
                        <h2>Driving Behaviour Insights</h2>
                        <div>
                          In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                        </div>
                      </div>
                      <img src="~@/assets/images/netstar-logo.svg" width="182" height="65" alt="Netstar" />
                    </div>
                    <div class="insights-container">
                      <div class="insights-panel">
                        <div class="insights-header">
                          <h2>Driving Behaviour Insights</h2>
                          <div>
                            In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                          </div>
                        </div>
                        <div class="insights-content">
                          <template v-if="selectedProvinces.length">
                            <div class="subtitle">Provinces</div>
                            <div>
                              <div v-for="province in selectedProvinces" :key="province.name" class="item-insight">
                                <strong class="item-name" :class="province.rating">{{ province.name }}</strong> had the
                                <strong>{{ getProvinceRank(province, "most") }}</strong> speeding incidents of all the provinces.
                              </div>
                            </div>
                            <div class="separator my-3" v-if="selectedCities.length"></div>
                          </template>
                          <template v-if="selectedCities.length">
                            <div class="subtitle">Cities</div>
                            <div>
                              <div v-for="city in selectedCities" :key="city.name" class="item-insight">
                                <strong class="item-name" :class="city.rating">{{ city.name }}</strong> had the
                                <strong>{{ getCityRank(city, "most") }}</strong> speeding incidents of all the cities.
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="map-panel">
                        <SouthAfricaMap
                          :key="componentKey"
                          :legend="mapLegend"
                          legendTitle="Speeding Incidents"
                          :printscreen="isPrintscreen"
                          :provinces="allProvinces"
                          :selectedProvinces="selectedProvinces"
                          :selectedCities="selectedCities"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="volumes-graph panel-content-graph" v-if="isCategoryGraph">
                    <div class="printscreen-header" v-if="isPrintscreen">
                      <button id="close-printscreen" class="btn-rounded nav-outline" :class="{ reveal: revealHiddenElements }" @click="closePrintscreen">
                        CLOSE
                      </button>
                      <div>
                        <h2>Driving Behaviour Insights</h2>
                        <div>
                          In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                        </div>
                      </div>
                      <img src="~@/assets/images/netstar-logo.svg" width="182" height="65" alt="Netstar" />
                    </div>
                    <div class="insights-container">
                      <div class="insights-panel">
                        <div class="insights-header">
                          <h2>Driving Behaviour Insights</h2>
                          <div>
                            In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                          </div>
                        </div>
                        <div class="insights-content">
                          <template v-if="selectedProvinces.length">
                            <div class="subtitle">Provinces</div>
                            <div>
                              <div v-for="province in selectedProvinces" :key="province.name" class="item-insight">
                                <strong class="item-name" :style="{ color: province.color }">{{ province.name }}</strong> had the
                                <strong>{{ getProvinceRank(province, "most") }}</strong> speeding incidents of all the provinces.
                              </div>
                            </div>
                            <div class="separator my-3" v-if="selectedCities.length"></div>
                          </template>
                          <template v-if="selectedCities.length">
                            <div class="subtitle">Cities</div>
                            <div>
                              <div v-for="city in selectedCities" :key="city.name" class="item-insight">
                                <strong class="item-name" :style="{ color: city.color }">{{ city.name }}</strong> had the
                                <strong>{{ getCityRank(city, "most") }}</strong> speeding incidents of all the cities.
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="graph-panel">
                        <LineChart :key="componentKey" :datasets="favourites" :printscreen="isPrintscreen" />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="favourites.length && isAccidents">
                  <div class="volumes-overview" v-if="isCategoryOverview">
                    <div v-for="province in selectedProvinces" :key="province.name" class="volumes-overview-item">
                      <div class="volumes-overview-item-header">
                        {{ province.name }}
                      </div>
                      <div class="volumes-overview-item-content" :class="{ up: province.changeDirection == 'up', down: province.changeDirection == 'down' }">
                        <div class="rank">
                          <strong :class="{ 'text-capitalize': isProvinceWithHighestRank(province) }">{{ getProvinceRank(province, "most") }}</strong> accidents
                          of all the provinces.
                        </div>
                        <div class="vehicle-count">
                          <div class="value">
                            {{ province.value.toLocaleString("en-ZA") }}
                          </div>
                          <div>Accidents</div>
                        </div>
                        <div class="vehicle-value-change">
                          <div class="icon">
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="province.changeDirection == 'up'">
                              <path fill="currentColor" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
                            </svg>
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="province.changeDirection == 'down'">
                              <path fill="currentColor" d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" />
                            </svg>
                          </div>
                          <div>
                            <strong>{{ province.changeDirection }} {{ province.percentageChange }}%</strong> compared to yesterday.
                          </div>
                        </div>
                      </div>
                      <div class="volumes-overview-item-footer" v-if="province.cities.length">
                        <div v-for="city in province.cities.slice(0, 3)" :key="city.name" class="city-volume">
                          <div class="name">{{ city.name }}</div>
                          <div class="text-end">
                            <div class="value">{{ city.value.toLocaleString("en-ZA") }}</div>
                            <div>Accidents</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-for="city in selectedCities" :key="city.name" class="volumes-overview-item">
                      <div class="volumes-overview-item-header">
                        {{ city.name }}
                      </div>
                      <div class="volumes-overview-item-content" :class="{ up: city.changeDirection == 'up', down: city.changeDirection == 'down' }">
                        <div class="rank">
                          <strong :class="{ 'text-capitalize': isCityWithHighestRank(city) }">{{ getCityRank(city, "most") }}</strong> accidents of all the
                          cities.
                        </div>
                        <div class="vehicle-count">
                          <div class="value">
                            {{ city.value.toLocaleString("en-ZA") }}
                          </div>
                          <div>Accidents</div>
                        </div>
                        <div class="vehicle-value-change">
                          <div class="icon">
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="city.changeDirection == 'up'">
                              <path fill="currentColor" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
                            </svg>
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="city.changeDirection == 'down'">
                              <path fill="currentColor" d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" />
                            </svg>
                          </div>
                          <div>
                            <strong>{{ city.changeDirection }} {{ city.percentageChange }}%</strong> compared to yesterday.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="volumes-map panel-content-map" v-if="isCategoryMap">
                    <div class="printscreen-header" v-if="isPrintscreen">
                      <button id="close-printscreen" class="btn-rounded nav-outline" :class="{ reveal: revealHiddenElements }" @click="closePrintscreen">
                        CLOSE
                      </button>
                      <div>
                        <h2>Driving Behaviour Insights</h2>
                        <div>
                          In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                        </div>
                      </div>
                      <img src="~@/assets/images/netstar-logo.svg" width="182" height="65" alt="Netstar" />
                    </div>
                    <div class="insights-container">
                      <div class="insights-panel">
                        <div class="insights-header">
                          <h2>Driving Behaviour Insights</h2>
                          <div>
                            In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                          </div>
                        </div>
                        <div class="insights-content">
                          <template v-if="selectedProvinces.length">
                            <div class="subtitle">Provinces</div>
                            <div>
                              <div v-for="province in selectedProvinces" :key="province.name" class="item-insight">
                                <strong class="item-name" :class="province.rating">{{ province.name }}</strong> had the
                                <strong>{{ getProvinceRank(province, "most") }}</strong> accidents of all the provinces.
                              </div>
                            </div>
                            <div class="separator my-3" v-if="selectedCities.length"></div>
                          </template>
                          <template v-if="selectedCities.length">
                            <div class="subtitle">Cities</div>
                            <div>
                              <div v-for="city in selectedCities" :key="city.name" class="item-insight">
                                <strong class="item-name" :class="city.rating">{{ city.name }}</strong> had the
                                <strong>{{ getCityRank(city, "most") }}</strong> accidents of all the cities.
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="map-panel">
                        <SouthAfricaMap
                          :key="componentKey"
                          :legend="mapLegend"
                          legendTitle="Accidents"
                          :printscreen="isPrintscreen"
                          :provinces="allProvinces"
                          :selectedProvinces="selectedProvinces"
                          :selectedCities="selectedCities"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="volumes-graph panel-content-graph" v-if="isCategoryGraph">
                    <div class="printscreen-header" v-if="isPrintscreen">
                      <button id="close-printscreen" class="btn-rounded nav-outline" :class="{ reveal: revealHiddenElements }" @click="closePrintscreen">
                        CLOSE
                      </button>
                      <div>
                        <h2>Driving Behaviour Insights</h2>
                        <div>
                          In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                        </div>
                      </div>
                      <img src="~@/assets/images/netstar-logo.svg" width="182" height="65" alt="Netstar" />
                    </div>
                    <div class="insights-container">
                      <div class="insights-panel">
                        <div class="insights-header">
                          <h2>Driving Behaviour Insights</h2>
                          <div>
                            In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                          </div>
                        </div>
                        <div class="insights-content">
                          <template v-if="selectedProvinces.length">
                            <div class="subtitle">Provinces</div>
                            <div>
                              <div v-for="province in selectedProvinces" :key="province.name" class="item-insight">
                                <strong class="item-name" :style="{ color: province.color }">{{ province.name }}</strong> had the
                                <strong>{{ getProvinceRank(province, "most") }}</strong> accidents of all the provinces.
                              </div>
                            </div>
                            <div class="separator my-3" v-if="selectedCities.length"></div>
                          </template>
                          <template v-if="selectedCities.length">
                            <div class="subtitle">Cities</div>
                            <div>
                              <div v-for="city in selectedCities" :key="city.name" class="item-insight">
                                <strong class="item-name" :style="{ color: city.color }">{{ city.name }}</strong> had the
                                <strong>{{ getCityRank(city, "most") }}</strong> accidents of all the cities.
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="graph-panel">
                        <LineChart :key="componentKey" :datasets="favourites" :printscreen="isPrintscreen" />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else-if="favourites.length && isHarshBraking">
                  <div class="volumes-overview" v-if="isCategoryOverview">
                    <div v-for="province in selectedProvinces" :key="province.name" class="volumes-overview-item">
                      <div class="volumes-overview-item-header">
                        {{ province.name }}
                      </div>
                      <div class="volumes-overview-item-content" :class="{ up: province.changeDirection == 'up', down: province.changeDirection == 'down' }">
                        <div class="rank">
                          <strong :class="{ 'text-capitalize': isProvinceWithHighestRank(province) }">{{ getProvinceRank(province, "most") }}</strong> harsh
                          braking incidents of all the provinces.
                        </div>
                        <div class="vehicle-count">
                          <div class="value">
                            {{ province.value.toLocaleString("en-ZA") }}
                          </div>
                          <div>Incidents</div>
                        </div>
                        <div class="vehicle-value-change">
                          <div class="icon">
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="province.changeDirection == 'up'">
                              <path fill="currentColor" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
                            </svg>
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="province.changeDirection == 'down'">
                              <path fill="currentColor" d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" />
                            </svg>
                          </div>
                          <div>
                            <strong>{{ province.changeDirection }} {{ province.percentageChange }}%</strong> compared to yesterday.
                          </div>
                        </div>
                      </div>
                      <div class="volumes-overview-item-footer" v-if="province.cities.length">
                        <div v-for="city in province.cities.slice(0, 3)" :key="city.name" class="city-volume">
                          <div class="name">{{ city.name }}</div>
                          <div class="text-end">
                            <div class="value">{{ city.value.toLocaleString("en-ZA") }}</div>
                            <div>Incidents</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-for="city in selectedCities" :key="city.name" class="volumes-overview-item">
                      <div class="volumes-overview-item-header">
                        {{ city.name }}
                      </div>
                      <div class="volumes-overview-item-content" :class="{ up: city.changeDirection == 'up', down: city.changeDirection == 'down' }">
                        <div class="rank">
                          <strong :class="{ 'text-capitalize': isCityWithHighestRank(city) }">{{ getCityRank(city, "most") }}</strong> harsh braking incidents
                          of all the cities.
                        </div>
                        <div class="vehicle-count">
                          <div class="value">
                            {{ city.value.toLocaleString("en-ZA") }}
                          </div>
                          <div>Incidents</div>
                        </div>
                        <div class="vehicle-value-change">
                          <div class="icon">
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="city.changeDirection == 'up'">
                              <path fill="currentColor" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
                            </svg>
                            <svg width="30" height="30" viewBox="0 0 24 24" v-if="city.changeDirection == 'down'">
                              <path fill="currentColor" d="M10,4H14V13L17.5,9.5L19.92,11.92L12,19.84L4.08,11.92L6.5,9.5L10,13V4Z" />
                            </svg>
                          </div>
                          <div>
                            <strong>{{ city.changeDirection }} {{ city.percentageChange }}%</strong> compared to yesterday.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="volumes-map panel-content-map" v-if="isCategoryMap">
                    <div class="printscreen-header" v-if="isPrintscreen">
                      <button id="close-printscreen" class="btn-rounded nav-outline" :class="{ reveal: revealHiddenElements }" @click="closePrintscreen">
                        CLOSE
                      </button>
                      <div>
                        <h2>Driving Behaviour Insights</h2>
                        <div>
                          In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                        </div>
                      </div>
                      <img src="~@/assets/images/netstar-logo.svg" width="182" height="65" alt="Netstar" />
                    </div>
                    <div class="insights-container">
                      <div class="insights-panel">
                        <div class="insights-header">
                          <h2>Driving Behaviour Insights</h2>
                          <div>
                            In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                          </div>
                        </div>
                        <div class="insights-content">
                          <template v-if="selectedProvinces.length">
                            <div class="subtitle">Provinces</div>
                            <div>
                              <div v-for="province in selectedProvinces" :key="province.name" class="item-insight">
                                <strong class="item-name" :class="province.rating">{{ province.name }}</strong> had the
                                <strong>{{ getProvinceRank(province, "most") }}</strong> harsh braking incidents of all the provinces.
                              </div>
                            </div>
                            <div class="separator my-3" v-if="selectedCities.length"></div>
                          </template>
                          <template v-if="selectedCities.length">
                            <div class="subtitle">Cities</div>
                            <div>
                              <div v-for="city in selectedCities" :key="city.name" class="item-insight">
                                <strong class="item-name" :class="city.rating">{{ city.name }}</strong> had the
                                <strong>{{ getCityRank(city, "most") }}</strong> harsh braking incidents of all the cities.
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="map-panel">
                        <SouthAfricaMap
                          :key="componentKey"
                          :legend="mapLegend"
                          legendTitle="Harsh Braking Incidents"
                          :printscreen="isPrintscreen"
                          :provinces="allProvinces"
                          :selectedProvinces="selectedProvinces"
                          :selectedCities="selectedCities"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="volumes-graph panel-content-graph" v-if="isCategoryGraph">
                    <div class="printscreen-header" v-if="isPrintscreen">
                      <button id="close-printscreen" class="btn-rounded nav-outline" :class="{ reveal: revealHiddenElements }" @click="closePrintscreen">
                        CLOSE
                      </button>
                      <div>
                        <h2>Driving Behaviour Insights</h2>
                        <div>
                          In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                        </div>
                      </div>
                      <img src="~@/assets/images/netstar-logo.svg" width="182" height="65" alt="Netstar" />
                    </div>
                    <div class="insights-container">
                      <div class="insights-panel">
                        <div class="insights-header">
                          <h2>Driving Behaviour Insights</h2>
                          <div>
                            In South Africa, on <strong>{{ getSelectedDateWithFormat("DD MMMM YYYY") }}</strong>
                          </div>
                        </div>
                        <div class="insights-content">
                          <template v-if="selectedProvinces.length">
                            <div class="subtitle">Provinces</div>
                            <div>
                              <div v-for="province in selectedProvinces" :key="province.name" class="item-insight">
                                <strong class="item-name" :style="{ color: province.color }">{{ province.name }}</strong> had the
                                <strong>{{ getProvinceRank(province, "most") }}</strong> harsh braking incidents of all the provinces.
                              </div>
                            </div>
                            <div class="separator my-3" v-if="selectedCities.length"></div>
                          </template>
                          <template v-if="selectedCities.length">
                            <div class="subtitle">Cities</div>
                            <div>
                              <div v-for="city in selectedCities" :key="city.name" class="item-insight">
                                <strong class="item-name" :style="{ color: city.color }">{{ city.name }}</strong> had the
                                <strong>{{ getCityRank(city, "most") }}</strong> harsh braking incidents of all the cities.
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="graph-panel">
                        <LineChart :key="componentKey" :datasets="favourites" :printscreen="isPrintscreen" />
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else>Please select a province or city.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu />
      <Footer />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.driving-behaviour-page {
  .main {
    > .content {
      .content-main {
        .panel {
          .volumes-overview {
            display: grid;
            grid-template-columns: repeat(4, 1fr); //repeat(auto-fill, 320px);
            gap: 30px;

            &-item {
              &-header {
                background: $brand-color-1;
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                padding: 15px;
              }

              &-content {
                background: #fff;
                padding: 15px;

                .rank {
                  height: 40px;
                }

                .vehicle-count {
                  text-align: right;
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: 1px solid $border-grey;

                  .value {
                    font-size: 40px;
                    font-weight: bold;
                  }
                }

                .vehicle-value-change {
                  display: flex;
                  align-items: center;

                  strong {
                    font-size: 16px;
                    text-transform: capitalize;
                  }
                }

                &.up {
                  .vehicle-count {
                    .value {
                      color: $red;
                    }
                  }

                  .vehicle-value-change {
                    .icon,
                    strong {
                      color: $red;
                    }
                  }
                }

                &.down {
                  .vehicle-count {
                    .value {
                      color: $green;
                    }
                  }

                  .vehicle-value-change {
                    .icon,
                    strong {
                      color: $green;
                    }
                  }
                }
              }

              &-footer {
                .city-volume {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8px 15px;

                  &:nth-child(1) {
                    background: #a7e0ee;
                  }

                  &:nth-child(2) {
                    background: #caecf5;
                  }

                  &:nth-child(3) {
                    background: #e4f5fa;
                  }

                  .name {
                    font-weight: bold;
                  }

                  .value {
                    font-size: 28px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .traffic-volumes-page {
    .main {
      > .content {
        .content-main {
          .panel {
            .volumes-overview {
              grid-template-columns: repeat(3, 1fr);
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import moment from "moment";
import randomColor from "randomcolor";
import { utilService } from "@/services";
import DatePicker from "@/components/DatePicker";
import Footer from "@/pages/components/Footer";
import Header from "@/pages/components/Header";
import LineChart from "@/components/LineChart";
import Menu from "@/components/Menu";
import ProvinceCitySelector from "@/components/ProvinceCitySelector";
import SmoothReflow from "@/components/SmoothReflow";
import SouthAfricaMap from "@/components/SouthAfricaMap";
import TimePicker from "@/components/TimePicker";
import Toggle from "@/components/Toggle";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "DrivingBehaviourPage",
  components: {
    DatePicker,
    Footer,
    Header,
    LineChart,
    Menu,
    ProvinceCitySelector,
    SmoothReflow,
    SouthAfricaMap,
    TimePicker,
    Toggle,
    VueSlickCarousel,
  },
  props: {
    category: {
      type: String,
      default: "Speeding",
    },
  },
  data() {
    return {
      loading: true,
      search: "",
      date: moment().format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      time: "Last 24 hours",
      showSidebar: true,
      isFullscreen: false,
      isPrintscreen: false,
      revealHiddenElements: false,
      viewType: "View All",
      viewTypes: ["View All"],
      componentKey: 0,
      selectedCategory: this.category,
      categories: {
        volumes: {
          displayName: "Speeding",
          viewTypes: {
            overview: {
              active: true,
            },
            map: {
              active: false,
            },
            graph: {
              active: false,
            },
          },
        },
        busiestRoutes: {
          displayName: "Accidents",
          viewTypes: {
            overview: {
              active: false,
            },
            map: {
              active: false,
            },
            graph: {
              active: false,
            },
          },
        },
        averageSpeed: {
          displayName: "Harsh Braking",
          viewTypes: {
            overview: {
              active: false,
            },
            map: {
              active: false,
            },
            graph: {
              active: false,
            },
          },
        },
      },
      provinceToSelect: {},
      cityToSelect: {},
      favourites: [],
      selectedProvinces: [],
      selectedCities: [],
      // statsAccordion: {
      //   trafficVolumes: {
      //     isOpen: true,
      //     busiestRoutesSliderSettings: {
      //       arrows: true,
      //       dots: false,
      //       infinite: true,
      //       speed: 500,
      //       slidesToShow: 3,
      //       slidesToScroll: 3,
      //     },
      //   },
      //   jammingAlerts: {
      //     isOpen: false,
      //   },
      //   drivingBehaviour: {
      //     isOpen: false,
      //   },
      // },
      stats: {
        speeding: {
          provinces: [
            {
              name: "Gauteng",
              value: 401,
              percentageChange: 6,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Johannesburg",
                  value: 202,
                  percentageChange: 5,
                  changeDirection: "up",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Pretoria",
                  value: 158,
                  percentageChange: 3,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Krugersdorp",
                  value: 41,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Roodepoort",
                  value: 32,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Western Cape",
              value: 297,
              percentageChange: 4,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Cape Town",
                  value: 139,
                  percentageChange: 1,
                  changeDirection: "up",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Stellenbosch",
                  value: 19,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Swellendam",
                  value: 13,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "KwaZulu-Natal",
              value: 54,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Durban",
                  value: 27,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Eastern Cape",
              value: 73,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Gqeberha",
                  value: 36,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Mthatha",
                  value: 12,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Free State",
              value: 55,
              percentageChange: 3,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Bloemfontein",
                  value: 41,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Sasolburg",
                  value: 2,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Limpopo",
              value: 48,
              percentageChange: 1,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Polokwane",
                  value: 28,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Mpumalanga",
              value: 42,
              percentageChange: 5,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
            {
              name: "North West",
              value: 31,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
            {
              name: "Northern Cape",
              value: 23,
              percentageChange: 1,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
          ],
        },
        accidents: {
          provinces: [
            {
              name: "Gauteng",
              value: 28,
              percentageChange: 6,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Johannesburg",
                  value: 12,
                  percentageChange: 5,
                  changeDirection: "up",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Pretoria",
                  value: 9,
                  percentageChange: 3,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Krugersdorp",
                  value: 5,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Roodepoort",
                  value: 3,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Western Cape",
              value: 14,
              percentageChange: 4,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Cape Town",
                  value: 6,
                  percentageChange: 1,
                  changeDirection: "up",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Stellenbosch",
                  value: 3,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Swellendam",
                  value: 1,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "KwaZulu-Natal",
              value: 7,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Durban",
                  value: 3,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Eastern Cape",
              value: 7,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Gqeberha",
                  value: 2,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Mthatha",
                  value: 4,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Free State",
              value: 4,
              percentageChange: 3,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Bloemfontein",
                  value: 2,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Sasolburg",
                  value: 1,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Limpopo",
              value: 4,
              percentageChange: 1,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Polokwane",
                  value: 1,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Mpumalanga",
              value: 3,
              percentageChange: 5,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
            {
              name: "North West",
              value: 2,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
            {
              name: "Northern Cape",
              value: 1,
              percentageChange: 1,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
          ],
        },
        harshBraking: {
          provinces: [
            {
              name: "Gauteng",
              value: 151,
              percentageChange: 6,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Johannesburg",
                  value: 82,
                  percentageChange: 5,
                  changeDirection: "up",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Pretoria",
                  value: 58,
                  percentageChange: 3,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Krugersdorp",
                  value: 11,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Roodepoort",
                  value: 9,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Western Cape",
              value: 84,
              percentageChange: 4,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Cape Town",
                  value: 46,
                  percentageChange: 1,
                  changeDirection: "up",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Stellenbosch",
                  value: 16,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Swellendam",
                  value: 8,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "KwaZulu-Natal",
              value: 59,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Durban",
                  value: 31,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Eastern Cape",
              value: 51,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Gqeberha",
                  value: 15,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Mthatha",
                  value: 6,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Free State",
              value: 47,
              percentageChange: 3,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Bloemfontein",
                  value: 32,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
                {
                  name: "Sasolburg",
                  value: 4,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Limpopo",
              value: 31,
              percentageChange: 1,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [
                {
                  name: "Polokwane",
                  value: 8,
                  percentageChange: 2,
                  changeDirection: "down",
                  timeSeries: [],
                  color: this.generateRandomColor(),
                },
              ],
            },
            {
              name: "Mpumalanga",
              value: 30,
              percentageChange: 5,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
            {
              name: "North West",
              value: 15,
              percentageChange: 2,
              changeDirection: "down",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
            {
              name: "Northern Cape",
              value: 12,
              percentageChange: 1,
              changeDirection: "up",
              timeSeries: [],
              color: this.generateRandomColor(),
              cities: [],
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState("auth", ["isAuthenticated"]),
    isSpeeding() {
      return this.selectedCategory == "Speeding";
    },
    isAccidents() {
      return this.selectedCategory == "Accidents";
    },
    isHarshBraking() {
      return this.selectedCategory == "Harsh Braking";
    },
    isCategoryOverview() {
      for (const category in this.categories) {
        for (const viewType in this.categories[category].viewTypes) {
          if (viewType == "overview" && this.categories[category].viewTypes[viewType].active) {
            return true;
          }
        }
      }
      return false;
    },
    isCategoryMap() {
      for (const category in this.categories) {
        for (const viewType in this.categories[category].viewTypes) {
          if (viewType == "map" && this.categories[category].viewTypes[viewType].active) {
            return true;
          }
        }
      }
      return false;
    },
    isCategoryGraph() {
      for (const category in this.categories) {
        for (const viewType in this.categories[category].viewTypes) {
          if (viewType == "graph" && this.categories[category].viewTypes[viewType].active) {
            return true;
          }
        }
      }
      return false;
    },
    searchOptions() {
      return this.allProvinces.concat(this.allCities);
    },
    allProvinces() {
      let provinces = [];

      if (this.isSpeeding) {
        provinces = JSON.parse(JSON.stringify(this.stats.speeding.provinces));

        provinces.forEach((province) => {
          province.rating = this.getSpeedingRating(province.value);
        });
      } else if (this.isAccidents) {
        provinces = JSON.parse(JSON.stringify(this.stats.accidents.provinces));

        provinces.forEach((province) => {
          province.rating = this.getAccidentRating(province.value);
        });
      } else if (this.isHarshBraking) {
        provinces = JSON.parse(JSON.stringify(this.stats.harshBraking.provinces));

        provinces.forEach((province) => {
          province.rating = this.getHarshBrakingRating(province.value);
        });
      }

      return provinces;
    },
    allCities() {
      const cities = [];
      let provinces = [];
      let ratingFunction = () => {};

      if (this.isSpeeding) {
        provinces = JSON.parse(JSON.stringify(this.stats.speeding.provinces));
        ratingFunction = (value) => this.getSpeedingRating(value);
      } else if (this.isAccidents) {
        provinces = JSON.parse(JSON.stringify(this.stats.accidents.provinces));
        ratingFunction = (value) => this.getAccidentRating(value);
      } else if (this.isHarshBraking) {
        provinces = JSON.parse(JSON.stringify(this.stats.harshBraking.provinces));
        ratingFunction = (value) => this.getHarshBrakingRating(value);
      }

      if (provinces) {
        provinces.forEach((province) => {
          province.cities.forEach((city) => {
            const cityWithProvince = city;
            cityWithProvince.province = province.name;
            cityWithProvince.rating = ratingFunction(province.value);

            cities.push(cityWithProvince);
          });
        });
      }

      cities.sort((a, b) => b.value - a.value);

      return cities;
    },
    mapLegend() {
      if (this.isSpeeding) {
        return [
          { label: "0 - 99", className: "very-low" },
          { label: "100 - 199", className: "low" },
          { label: "200 - 299", className: "medium" },
          { label: "300 - 399", className: "high" },
          { label: "400+", className: "very-high" },
        ];
      } else if (this.isAccidents) {
        return [
          { label: "0 - 9", className: "very-low" },
          { label: "10 - 19", className: "low" },
          { label: "20 - 29", className: "medium" },
          { label: "30 - 39", className: "high" },
          { label: "40+", className: "very-high" },
        ];
      } else if (this.isHarshBraking) {
        return [
          { label: "0 - 99", className: "very-low" },
          { label: "100 - 199", className: "low" },
          { label: "200 - 299", className: "medium" },
          { label: "300 - 399", className: "high" },
          { label: "400+", className: "very-high" },
        ];
      }
    },
  },
  created() {
    this.loading = false;
  },
  mounted() {
    this.stats.speeding.provinces.forEach((province) => {
      province.timeSeries = [];
      for (let i = 0; i < 24; i++) {
        province.timeSeries.push({
          dateTime: "2022-08-25T" + String(i).padStart(2, "0") + ":00:00",
          value: Math.round(province.value * Math.random()),
        });
      }

      province.cities.forEach((city) => {
        city.timeSeries = [];
        for (let j = 0; j < 24; j++) {
          city.timeSeries.push({
            dateTime: "2022-08-25T" + String(j).padStart(2, "0") + ":00:00",
            value: Math.round(city.value * Math.random()),
          });
        }
      });
    });

    this.stats.accidents.provinces.forEach((province) => {
      province.timeSeries = [];
      for (let i = 0; i < 24; i++) {
        province.timeSeries.push({
          dateTime: "2022-08-25T" + String(i).padStart(2, "0") + ":00:00",
          value: Math.round(province.value * Math.random()),
        });
      }

      province.cities.forEach((city) => {
        city.timeSeries = [];
        for (let j = 0; j < 24; j++) {
          city.timeSeries.push({
            dateTime: "2022-08-25T" + String(j).padStart(2, "0") + ":00:00",
            value: Math.round(city.value * Math.random()),
          });
        }
      });
    });

    this.stats.harshBraking.provinces.forEach((province) => {
      province.timeSeries = [];
      for (let i = 0; i < 24; i++) {
        province.timeSeries.push({
          dateTime: "2022-08-25T" + String(i).padStart(2, "0") + ":00:00",
          value: Math.round(province.value * Math.random()),
        });
      }

      province.cities.forEach((city) => {
        city.timeSeries = [];
        for (let j = 0; j < 24; j++) {
          city.timeSeries.push({
            dateTime: "2022-08-25T" + String(j).padStart(2, "0") + ":00:00",
            value: Math.round(city.value * Math.random()),
          });
        }
      });
    });
  },
  methods: {
    searchChange(value) {
      if (this.allProvinces.includes(value)) {
        if (!this.selectedProvinces.includes(value)) {
          this.provinceToSelect = value;
        }
      } else {
        if (!this.selectedCities.includes(value)) {
          this.cityToSelect = value;
        }
      }
      this.search = "";
    },
    handleCategoryChange(category) {
      this.selectedCategory = category;
    },
    handleCategoryViewTypeChange(viewType) {
      for (const category in this.categories) {
        for (const viewType in this.categories[category].viewTypes) {
          this.categories[category].viewTypes[viewType].active = false;
        }
      }

      if (this.isSpeeding) {
        this.categories.volumes.viewTypes[viewType].active = true;
      } else if (this.isAccidents) {
        this.categories.busiestRoutes.viewTypes[viewType].active = true;
      } else if (this.isHarshBraking) {
        this.categories.averageSpeed.viewTypes[viewType].active = true;
      }
    },
    viewTypeChange() {},
    getProvinceRank(province, rankText) {
      let rank = "";
      for (let i = 0; i < this.allProvinces.length; i++) {
        if (province.value >= this.allProvinces[i].value) {
          rank = i + 1;
          break;
        }
      }

      if (rank == 1) {
        return rankText;
      }

      return moment.localeData().ordinal(rank) + " " + rankText;
    },
    isProvinceWithHighestRank(province) {
      return province.value == this.allProvinces[0].value;
    },
    getCityRank(city, rankText) {
      let rank = "";
      for (let i = 0; i < this.allCities.length; i++) {
        if (city.value >= this.allCities[i].value) {
          rank = i + 1;
          break;
        }
      }

      if (rank == 1) {
        return rankText;
      }

      return moment.localeData().ordinal(rank) + " " + rankText;
    },
    isCityWithHighestRank(city) {
      return city.value == this.allCities[0].value;
    },
    getSelectedDateWithFormat(dateFormat) {
      return moment(this.date).format(dateFormat);
    },
    getSpeedingRating(count) {
      if (count < 100) {
        return "very-low";
      } else if (count < 200) {
        return "low";
      } else if (count < 300) {
        return "medium";
      } else if (count < 400) {
        return "high";
      } else {
        return "very-high";
      }
    },
    getAccidentRating(count) {
      if (count < 10) {
        return "very-low";
      } else if (count < 20) {
        return "low";
      } else if (count < 30) {
        return "medium";
      } else if (count < 40) {
        return "high";
      } else {
        return "very-high";
      }
    },
    getHarshBrakingRating(count) {
      if (count < 100) {
        return "very-low";
      } else if (count < 200) {
        return "low";
      } else if (count < 300) {
        return "medium";
      } else if (count < 400) {
        return "high";
      } else {
        return "very-high";
      }
    },
    openFullscreen() {
      this.isFullscreen = true;
      document.body.classList.add("overflow-hidden");
    },
    closeFullscreen() {
      this.isFullscreen = false;
      document.body.classList.remove("overflow-hidden");
    },
    openPrintscreen() {
      this.isFullscreen = true;
      this.isPrintscreen = true;
      document.body.classList.add("overflow-hidden");

      this.revealHiddenElements = true;

      setTimeout(() => {
        this.revealHiddenElements = false;
      }, 3000);
    },
    closePrintscreen() {
      this.isFullscreen = false;
      this.isPrintscreen = false;
      document.body.classList.remove("overflow-hidden");
    },
    handleMouseMove() {
      if (this.isPrintscreen) {
        if (!this.revealHiddenElements) {
          this.revealHiddenElements = true;

          setTimeout(() => {
            this.revealHiddenElements = false;
          }, 1000);
        }
      }
    },
    generateRandomColor() {
      // return randomColor({ luminosity: "light" });
      // return randomColor({ hue: "blue" });
      return randomColor();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    slugify(str) {
      return utilService.slugify(str);
    },
  },
  watch: {
    isFullscreen() {
      this.forceRerender();
    },
    isPrintscreen() {
      this.forceRerender();
    },
    favourites() {
      this.forceRerender();
    },
  },
};
</script>