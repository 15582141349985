function initialState() {
  return {
    selectedProvinceNames: [],
    selectedCityNames: [],
    // selectedProvinceNames: ["Gauteng", "Western Cape"],
    // selectedCityNames: ["Johannesburg", "Cape Town"],
  };
}

const state = initialState();

const getters = {
  getSelectedProvinceNames: (state) => {
    return state.selectedProvinceNames;
  },
  getSelectedCityNames: (state) => {
    return state.selectedCityNames;
  },
};

const mutations = {
  selectProvince(state, provinceName) {
    state.selectedProvinceNames.push(provinceName);
  },
  deselectProvince(state, provinceName) {
    state.selectedProvinceNames = state.selectedProvinceNames.filter((item) => {
      return item != provinceName;
    });
  },
  selectCity(state, cityName) {
    state.selectedCityNames.push(cityName);
  },
  deselectCity(state, cityName) {
    state.selectedCityNames = state.selectedCityNames.filter((item) => {
      return item != cityName;
    });
  },
};

export const provinceCitySelector = {
  namespaced: true,
  state,
  getters,
  mutations,
};
