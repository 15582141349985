import moment from "moment";

function initialState() {
  return {
    date: null,
  };
}

const state = initialState();

const mutations = {
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  updateDate(state, value) {
    state.date = value;
  },
};

export const datePicker = {
  namespaced: true,
  state,
  mutations,
};
