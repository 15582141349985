<template>
  <header>
    <div class="header1" v-if="isAuthenticated">
      <div class="section-menu">
        <div>
          <div class="menu-item logo" @click="goToPage('Home')">
            <img src="~@/assets/images/small-logo.png" width="32" height="32" alt="Netstar" />
          </div>
        </div>
        <div>
          <div class="menu-item" :class="{ active: this.pageName == 'TrafficVolumes' }" @click="goToPage('TrafficVolumes')">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30.084 28.502">
              <g transform="translate(0.75 0.75)">
                <path
                  d="M27.011,6.819H12.118a2.907,2.907,0,0,0-2.74,2.456L7.9,16.48H31.229L29.75,9.275A2.9,2.9,0,0,0,27.011,6.819Z"
                  transform="translate(-5.263 -6.819)"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M24.278,16.671v6.238a2.194,2.194,0,0,1-2.19,2.2h-1.27a2.193,2.193,0,0,1-2.19-2.2V19.747"
                  transform="translate(4.228 1.897)"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M12.151,19.747v3.163a2.194,2.194,0,0,1-2.19,2.2H8.693a2.195,2.195,0,0,1-2.192-2.2V16.671"
                  transform="translate(-6.501 1.897)"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M31.872,11.945H9.712a3.49,3.49,0,0,0-3.21,3.7v4.589a3.488,3.488,0,0,0,3.21,3.7H31.872a3.489,3.489,0,0,0,3.212-3.7V15.643A3.491,3.491,0,0,0,31.872,11.945Z"
                  transform="translate(-6.5 -2.284)"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M12.679,16.43A2.376,2.376,0,1,1,10.3,14.046,2.38,2.38,0,0,1,12.679,16.43Z"
                  transform="translate(-5.238 -0.426)"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M22.359,16.43a2.378,2.378,0,1,1-2.377-2.384A2.382,2.382,0,0,1,22.359,16.43Z"
                  transform="translate(3.322 -0.426)"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path d="M14.86,13.132a3.1,3.1,0,1,1,6.2,0" transform="translate(0.894 -3.984)" fill="none" stroke="currentColor" stroke-width="1.5" />
                <line x2="7.358" transform="translate(10.49 15.626)" fill="none" stroke="currentColor" stroke-width="1.5" />
              </g>
            </svg>
            <div class="menu-title">Traffic Volumes</div>
          </div>
        </div>
        <div>
          <div class="menu-item" :class="{ active: this.pageName == 'RemoteJammingAlerts' }" @click="goToPage('RemoteJammingAlerts')">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25.945 34.796">
              <g id="Group_1955" data-name="Group 1955" transform="translate(1.06 0.75)">
                <path
                  d="M26.136,58.384H2.312V53.541a2.331,2.331,0,0,1,2.331-2.33H23.805a2.33,2.33,0,0,1,2.33,2.33Z"
                  transform="translate(-2.312 -25.088)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  d="M25.6,35.164H10.46V24.387a7.572,7.572,0,0,1,15.144,0Z"
                  transform="translate(-6.12 -9.013)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  d="M17.111,27.644a4.034,4.034,0,0,1,4.034-4.034"
                  transform="translate(-9.228 -12.189)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <line
                  x2="2.125"
                  y2="2.125"
                  transform="translate(0 5.046)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <line
                  x1="2.125"
                  y2="2.125"
                  transform="translate(21.699 5.046)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <line
                  y2="3.005"
                  transform="translate(11.911 0)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>

            <div class="menu-title">Remote Jamming Alerts</div>
          </div>
        </div>
        <!-- <div>
          <div class="menu-item" :class="{ active: this.pageName == 'DrivingBehaviour' }" @click="goToPage('DrivingBehaviour')">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 33.409 30.134">
              <g id="Group_1953" data-name="Group 1953" transform="translate(0.794 0.75)">
                <path
                  d="M12.9,2.526,1.049,23.055a4.053,4.053,0,0,0,3.51,6.079h23.7a4.052,4.052,0,0,0,3.509-6.079L19.92,2.526a4.052,4.052,0,0,0-7.018,0"
                  transform="translate(-0.5 -0.5)"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M12.9,2.526,1.049,23.055a4.053,4.053,0,0,0,3.51,6.079h23.7a4.052,4.052,0,0,0,3.509-6.079L19.92,2.526A4.052,4.052,0,0,0,12.9,2.526Z"
                  transform="translate(-0.5 -0.5)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <line
                  y2="12.747"
                  transform="translate(15.911 6.506)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path d="M14.4,19.117a1.233,1.233,0,1,1-1.233-1.233A1.233,1.233,0,0,1,14.4,19.117" transform="translate(2.747 4.375)" fill="currentColor" />
              </g>
            </svg>

            <div class="menu-title">Driving Behaviour</div>
          </div>
        </div> -->
      </div>
      <div :class="{ shadowWrap: !show && !$isMobile }">
        <div class="profile" :class="{ active: show }">
          <div class="welcome">
            <div>Welcome</div>
            <!-- <div class="name">{{ getDisplayName }}</div> -->
          </div>
          <!-- <img :src="getProfileImageUrl" class="inside-menu" width="32" height="32" alt="Avatar" v-show="!$isMobile || ($isMobile && show)" /> -->
          <button class="menu-button" key="on" @click="updateShow(false)" v-if="show">
            <svg viewBox="0 0 24 24" class="menu-icon">
              <title>Close</title>
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
              />
            </svg>
          </button>
          <button class="menu-button" key="off" @click="updateShow(true)" v-else>
            <!-- <img :src="getProfileImageUrl" width="42" height="42" alt="Avatar" v-if="$isMobile && getProfileImageUrl" /> -->
            <svg viewBox="0 0 100 80" width="20" height="20">
              <rect width="100" height="10" />
              <rect y="30" width="100" height="10" />
              <rect y="60" width="100" height="10" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="header2" v-else>
      <div class="shadowWrap">
        <div class="logo">
          <img src="~@/assets/images/netstar-logo.svg" width="151" height="54" alt="Netstar" />
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.header1 {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 52px;
  z-index: 2;

  .shadowWrap {
    position: relative;
    box-shadow: $drop-shadow;
    transition: filter 2s;
  }

  .section-menu {
    display: flex;
    box-shadow: $drop-shadow;

    > div {
      position: relative;
    }

    .menu-item {
      display: flex;
      align-items: center;
      background: #fff;
      height: 100%;
      padding: 10px 20px 10px 35px;

      &:hover {
        cursor: pointer;
      }

      &:after {
        content: " ";
        background: #fff;
        position: absolute;
        width: 20px;
        height: 100%;
        right: -10px;
        top: 0;
        z-index: 1;
        transform: skew(-20deg);
        box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
      }

      &.active {
        background: $brand-color-2;
        color: #fff;

        &:after {
          background: $brand-color-2;
        }
      }

      &.logo {
        width: 64px;
        padding-left: 20px;
      }

      .menu-title {
        font-size: 13px;
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    background: #fff;
    width: 250px;
    height: 100%;
    line-height: 1;
    font-weight: bold;
    font-size: 0.8125rem;
    font-style: italic;
    color: $brand-color-1;
    text-align: right;
    text-transform: uppercase;
    padding-right: 10px;

    &:before {
      content: " ";
      background: #fff;
      position: absolute;
      width: 20px;
      height: 100%;
      left: -10px;
      top: 0;
      z-index: 1;
      transform: skew(20deg);
      box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    }

    &.active {
      box-shadow: $drop-shadow;
    }

    .menu-button {
      svg {
        fill: $brand-color-1;
      }
    }

    .mobile & {
      background: transparent;
      justify-content: flex-end;
      transition: background 1s;

      &:before {
        display: none;
      }

      .menu-button {
        padding: 10px;
      }

      img {
        margin-right: 0;
        box-shadow: $drop-shadow;

        &.inside-menu {
          box-shadow: none;
        }
      }

      .welcome,
      img.inside-menu {
        opacity: 0;
        transform: translateX(-10px);
        transition: all 1s;
      }

      &.active {
        background: #fff;
        box-shadow: none;

        .welcome,
        img.inside-menu {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    .welcome {
      flex: 1;
      width: 150px;
      padding: 0 10px 0 15px;

      .name {
        color: $brand-color-2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    img {
      margin-right: 5px;
      border-radius: 50%;
    }
  }

  .menu-button {
    background-color: transparent;
    color: gray;
    cursor: pointer;
    margin: 0;
    border: 1px solid transparent;

    &:focus {
      outline: 0;
    }

    .menu-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.header2 {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .shadowWrap {
    position: relative;
    box-shadow: $omni-shadow;
  }

  .logo {
    background: #fff;
    padding: 25px 35px;

    &:after {
      content: " ";
      background: #fff;
      position: absolute;
      width: 40px;
      height: 100%;
      right: -20px;
      top: 0;
      z-index: 0;
      transform: skew(-20deg);
      box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { router } from "@/router";

export default {
  name: "Header",
  props: {
    pageName: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("auth", ["isAuthenticated"]),
    ...mapState("navigation", ["show"]),
    // ...mapGetters("account", ["getDisplayName", "getProfileImageUrl"]),
  },
  methods: {
    ...mapMutations("navigation", ["updateShow"]),
    goToPage(pageName) {
      if (this.$route.name !== pageName) {
        router.push({ name: pageName });
      }
    },
  },
};
</script>
