<template>
  <div class="spinner-component">
    <span class="spinner-border" :class="!large ? 'spinner-border-sm' : ''" role="status" aria-hidden="true" />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.spinner-component {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
export default {
  name: "Spinner",
  props: { large: Boolean },
};
</script>